import Vue from 'vue';
import Vuex from 'vuex';
import createMultiTabState from 'vuex-multi-tab-state';

// shopify
import {
    ACTION_UPDATE_PAGE_UNLOADED,
    MODULE_NAME as SHOPIFY_MODULE_GLOBAL,
} from '@/store/minicart/shopify/global/constants';
import shopify_global from '@/store/minicart/shopify/global/global';

import {
    MODULE_NAME as SHOPIFY_MODULE_CART,
    ACTION_ADD_TO_CART,
    ACTION_CHANGE,
    ACTION_ERROR,
    ACTION_GET_CART,
    ACTION_REMOVE_ITEM,
    ACTION_REMOVE_ITEMS,
    ACTION_UPDATE,
    ACTION_SUCCESS,
    ACTION_SET_CART,
} from '@/store/minicart/shopify/cart/constants';

import shopify_cart from '@/store/minicart/shopify/cart/cart';

import {
    MODULE_NAME as SHOPIFY_MODULE_CUSTOMER
} from '@/store/minicart/shopify/customer/constants';
import shopify_customer from '@/store/minicart/shopify/customer/customer';

// minicart
import { MODULE_NAME as MODULE_CART } from '@/store/minicart/cart/constants';
import cart from '@/store/minicart/cart/cart';

import { MODULE_NAME as MODULE_BOXES } from '@/store/minicart/boxes/constants';
import boxes from '@/store/minicart/boxes/boxes';

import {
    MODULE_NAME as MODULE_MEMBER_PRODUCT,
    ACTION_ADD_LUX_KEY_ITEM,
    ACTION_REMOVE_LUX_KEY_ITEMS,
} from '@/store/minicart/lux_product/constants';

import lux_product from '@/store/minicart/lux_product/lux_product';

import { MODULE_NAME as MODULE_BUNDLES } from '@/store/minicart/bundles/constants';
import bundles from '@/store/minicart/bundles/bundles';

import  { MODULE_NAME as MODULE_ROUTE_PROTECTION } from '@/store/minicart/route-protection/constants';
import route_protection from '@/store/minicart/route-protection/route-protection';

// dashboard
import { MODULE_NAME as MODULE_DASHBOARD } from '@/store/dashboard/constants';
import dashboard from '@/store/dashboard/dashboard';

import { MODULE_NAME as MODULE_PRODUCTS } from '@/store/dashboard/products/constants';
import dashboard_products from '@/store/dashboard/products/products';

import  {MODULE_NAME as MODULE_DASHBOARD_BOXES } from '@/store/dashboard/boxes/constants';
import dashboard_boxes from '@/store/dashboard/boxes/boxes';

import  { MODULE_NAME as MODULE_DASHBOARD_ADDRESSES } from '@/store/dashboard/addresses/constants';
import dashboard_addresses from '@/store/dashboard/addresses/addresses';

import  { MODULE_NAME as MODULE_DASHBOARD_COUNTRIES } from '@/store/dashboard/counties/constants';
import dashboard_countries from '@/store/dashboard/counties/countries';

import  { MODULE_NAME as MODULE_COLORS } from '@/store/dashboard/colors/constants';
import dashboard_colors from '@/store/dashboard/colors/colors';

import  { MODULE_NAME as MODULE_STYLES } from '@/store/dashboard/styles/constants';
import dashboard_styles from '@/store/dashboard/styles/styles';

import  { MODULE_NAME as MODULE_DASHBOARD_ORDERS } from '@/store/dashboard/orders/constants';
import dashboard_orders from '@/store/dashboard/orders/orders';

import  { MODULE_NAME as MODULE_DASHBOARD_SHOPIFY_ORDERS } from '@/store/dashboard/shopify_orders/constants';
import dashboard_shopify_orders from '@/store/dashboard/shopify_orders/orders';

import { MODULE_NAME as MODULE_CDN_PRODUCTS } from '@/store/dashboard/cdn_products/constants';
import dashboard_cdn_products from '@/store/dashboard/cdn_products/cdn_products';

import {
    MODULE_NAME as MODULE_DASHBOARD_WISHLIST,
    ACTION_ADD_TO_WISHLIST,
    ACTION_REMOVE_FROM_WISHLIST,
    ACTION_TOGGLE_ITEM_IN_WISHLIST,
    MODULE_NAME,
} from '@/store/dashboard/wishlist/constants';

import dashboard_wishlist from '@/store/dashboard/wishlist/wishlist';

import {
    openMinicartBySearchParams,
    processInveterateProducts,
    updateCartCounters,
} from "@/helpers/minicart";

import xhook from '@packages/xhook/xhook';

import {
    EVENT_MINICART_ERROR,
    EVENT_MINICART_INITIALIZED,
    EVENT_MINICART_ITEMS_COUNT,
    EVENT_MINICART_MEMBERSHIP_ADD,
    EVENT_MINICART_MEMBERSHIP_REMOVE,
    EVENT_MINICART_OPEN,
    EVENT_MINICART_PRODUCT_ADD,
    EVENT_MINICART_PRODUCT_REMOVE,
    EVENT_MINICART_PRODUCTS_CHANGE,
    EVENT_MINICART_PRODUCTS_REMOVE,
    EVENT_MINICART_PRODUCTS_UPDATE,
    EVENT_MINICART_RELOAD,
    EVENT_MINICART_RELOAD_SILENTLY,
    EVENT_MINICART_UPDATED,
    EVENT_WISHLIST_ADD,
    EVENT_WISHLIST_INITIALIZED,
    EVENT_WISHLIST_REMOVE,
    EVENT_WISHLIST_TOGGLE,
} from "@/store/events";

import { ERROR_NETWORK } from '@/helpers/error-codes';

Vue.use(Vuex);

export const getStore = () => {
    if (!window?.persistedStore) {
        window.persistedStore = new Vuex.Store({
            modules: {
                // shopify
                [SHOPIFY_MODULE_GLOBAL]: shopify_global,
                [SHOPIFY_MODULE_CUSTOMER]: shopify_customer,
                [SHOPIFY_MODULE_CART]: shopify_cart,
                [MODULE_MEMBER_PRODUCT]: lux_product,

                // core
                [MODULE_CART]: cart,
                [MODULE_BOXES]: boxes,
                [MODULE_BUNDLES]: bundles,
                [MODULE_ROUTE_PROTECTION]: route_protection,

                // dashboard
                [MODULE_DASHBOARD]: dashboard,
                [MODULE_DASHBOARD_BOXES]: dashboard_boxes,
                [MODULE_DASHBOARD_COUNTRIES]: dashboard_countries,
                [MODULE_DASHBOARD_ADDRESSES]: dashboard_addresses,
                [MODULE_DASHBOARD_ORDERS]: dashboard_orders,
                [MODULE_DASHBOARD_SHOPIFY_ORDERS]: dashboard_shopify_orders,
                [MODULE_CDN_PRODUCTS]: dashboard_cdn_products,
                [MODULE_DASHBOARD_WISHLIST]: dashboard_wishlist,
                [MODULE_PRODUCTS]: dashboard_products,
                [MODULE_COLORS]: dashboard_colors,
                [MODULE_STYLES]: dashboard_styles
            },
            plugins: [
                createMultiTabState({
                    statesPaths: [
                        'shopify_cart.cart',
                        // 'shopify_customer.customer'
                    ],
                    onBeforeReplace: (state) => {
                        const cart = state?.shopify_cart?.cart;
                        if (!cart) {
                            return state;
                        }

                        document.dispatchEvent(new CustomEvent(EVENT_MINICART_UPDATED, {
                            detail: {
                                cart: state?.shopify_cart?.cart,
                                customer: state?.shopify_customer?.customer || null
                            }
                        }));

                        document.dispatchEvent(new CustomEvent(EVENT_MINICART_ITEMS_COUNT, {
                            detail: {
                                item_count: cart.item_count
                            }
                        }));

                        return state;
                    },
                }),
                (store) => {
                    store.subscribeAction({
                        before() {
                            return !!window?.navigator?.onLine;
                        }
                    });
                }
            ]
        });
    }

    return window?.persistedStore;
};
export const store = getStore();

xhook.before((request) => {
    if (!!request?.url?.includes("monorail-edge.shopifysvc.com/v1/produce")) {
        return new Response("", {
            status: 200,
        });
    }

    return undefined;
});

xhook.before(async (request) => {
    const url = request?.url;
    if (!url) {
        return undefined;
    }

    const parsedUrl = new URL(url, window?.location?.origin || "");
    if (!parsedUrl) {
        return undefined;
    }

    const regex = /\/cart(\/(update|add|change|clear|async_shipping_rates|prepare_shipping_rates))?\.js(\?.+)?/gm;
    if (!url?.match(regex)) {
        return undefined;
    }

    if (!!parsedUrl?.pathname?.includes('/cart.js')) {
        return undefined;
    }

    if (!!parsedUrl?.pathname?.includes('/cart/update.js')) {
        return undefined;
    }

    if (!!parsedUrl?.pathname?.includes('/cart/add.js')) {
        return undefined;
    }

    if (!!parsedUrl?.pathname?.includes('/cart/change.js')) {
        return undefined;
    }

    if (!!parsedUrl?.pathname?.includes('/cart/clear.js')) {
        return undefined;
    }

    return undefined;
});

xhook.after(async (request, response) => {
    const url = request?.url;
    if (!url) {
        return undefined;
    }

    const parsedUrl = new URL(url, window?.location?.origin || "");
    if (!parsedUrl) {
        return undefined;
    }

    const regex = /\/cart(\/(update|add|change|clear|async_shipping_rates|prepare_shipping_rates))?\.js(\?.+)?/gm;
    if (!url?.match(regex)) {
        return response;
    }

    if (!!parsedUrl?.pathname?.includes('/cart.js')) {
        const cart = await response.json();

        await getStore().dispatch(`${SHOPIFY_MODULE_CART}/${ACTION_SET_CART}`, {
            cart,
        }, {
            root: true,
        });

        return undefined;
    }

    if (!!parsedUrl?.pathname?.includes('/cart/update.js')) {
        await getStore().dispatch(`${SHOPIFY_MODULE_CART}/${ACTION_GET_CART}`, {}, {
            root: true,
        });

        return undefined;
    }

    if (!!parsedUrl?.pathname?.includes('/cart/add.js')) {
        await getStore().dispatch(`${SHOPIFY_MODULE_CART}/${ACTION_GET_CART}`, {}, {
            root: true,
        });

        return undefined;
    }

    if (!!parsedUrl?.pathname?.includes('/cart/change.js')) {
        await getStore().dispatch(`${SHOPIFY_MODULE_CART}/${ACTION_GET_CART}`, {}, {
            root: true,
        });

        return undefined;
    }

    if (!!parsedUrl?.pathname?.includes('/cart/clear.js')) {
        await getStore().dispatch(`${SHOPIFY_MODULE_CART}/${ACTION_GET_CART}`, {}, {
            root: true,
        });

        return undefined;
    }

    return undefined;
});

if (document.readyState === "complete") {
    getStore().dispatch(`${SHOPIFY_MODULE_GLOBAL}/${ACTION_UPDATE_PAGE_UNLOADED}`, {
        page_unloaded: false,
    }, {
        root: true,
    });
} else {
    document.addEventListener('DOMContentLoaded', async () => {
        await getStore().dispatch(`${SHOPIFY_MODULE_GLOBAL}/${ACTION_UPDATE_PAGE_UNLOADED}`, {
            page_unloaded: false,
        }, {
            root: true,
        });
    });
}

window.addEventListener('beforeunload', () => {
    document.addEventListener('DOMContentLoaded', async () => {
        await getStore().dispatch(`${SHOPIFY_MODULE_GLOBAL}/${ACTION_UPDATE_PAGE_UNLOADED}`, {
            page_unloaded: true,
        }, {
            root: true,
        });
    });
});

window.addEventListener("offline", async () => {
    await getStore().dispatch(`${SHOPIFY_MODULE_CART}/${ACTION_ERROR}`, { error_code: ERROR_NETWORK }, {
        root: true,
    });
});

window.addEventListener("online", async () => {
    await getStore().dispatch(`${SHOPIFY_MODULE_CART}/${ACTION_SUCCESS}`, {
        message: "Connection is back. Reloading this page",
        when_minicart_opened: false,
    }, {
        root: true,
    });

    setTimeout(() => {
        window.location.reload();
    }, 5000);
});

const defaultAddToCard = async (event) => {
    const { detail } = event;
    if (!detail) {
        return;
    }

    const { items } = detail;
    if (!items) {
        return;
    }

    const errorCallback = typeof event.detail.errorCallback === 'function' ? event.detail.errorCallback : () => {};

    await getStore().dispatch(`${SHOPIFY_MODULE_CART}/${ACTION_ADD_TO_CART}`, {
        products: items,
        errorCallback,
        successCallback: async () => {
            const { silent = false } = detail;
            if (!!silent) {
                return;
            }

            document.dispatchEvent(new CustomEvent(EVENT_MINICART_OPEN));
        }
    });

    const { callback } = detail;

    if (typeof callback === 'function') {
        await callback();
    }
};

document.addEventListener(EVENT_MINICART_INITIALIZED, (event) => {
    openMinicartBySearchParams();

    const { detail } = event;
    if (!detail) {
        return;
    }

    const { cart } = detail;
    if (!cart) {
        return;
    }

    const { customer = null } = detail;

    try {
        processInveterateProducts(cart, customer);
    } catch (error) {}
});

document.addEventListener(EVENT_MINICART_UPDATED, (event) => {
    const { detail } = event;
    if (!detail) {
        return;
    }

    const { cart } = detail;
    if (!cart) {
        return;
    }

    const { customer } = detail;

    try {
        processInveterateProducts(cart, customer);
    } catch (error) {}
});

document.addEventListener(EVENT_MINICART_PRODUCT_ADD, async (event) => {
    await defaultAddToCard(event);
});

document.addEventListener(EVENT_MINICART_PRODUCT_REMOVE, async (event) => {
    const { detail } = event;
    if (!detail) {
        return;
    }

    const { key } = detail;
    if (!key) {
        return;
    }

    const errorCallback = typeof event.detail.errorCallback === 'function' ? await event.detail.errorCallback : () => {};

    await getStore().dispatch(`${SHOPIFY_MODULE_CART}/${ACTION_REMOVE_ITEM}`, {
        key,
        errorCallback,
        successCallback: async () => {
            document.dispatchEvent(new CustomEvent(EVENT_MINICART_OPEN));
        }
    });

    const { callback } = detail;

    if (typeof callback === 'function') {
        await callback();
    }
});

document.addEventListener(EVENT_MINICART_PRODUCTS_UPDATE, async (event) => {
    const { detail } = event;
    if (!detail) {
        return;
    }

    const { updates } = detail;
    if (!updates) {
        return;
    }

    const errorCallback = typeof event.detail.errorCallback === 'function' ? await event.detail.errorCallback : () => {};

    await getStore().dispatch(`${SHOPIFY_MODULE_CART}/${ACTION_UPDATE}`, {
        updates,
        errorCallback,
        successCallback: async () => {
            document.dispatchEvent(new CustomEvent(EVENT_MINICART_OPEN));
        }
    });

    const { callback } = detail;

    if (typeof callback === 'function') {
        await callback();
    }
});

document.addEventListener(EVENT_MINICART_PRODUCTS_CHANGE, async (event) => {
    const { detail } = event;
    if (!detail) {
        return;
    }

    const { item } = detail;
    if (!item) {
        return;
    }

    const errorCallback = typeof event.detail.errorCallback === 'function' ? await event.detail.errorCallback : () => {};

    await getStore().dispatch(`${SHOPIFY_MODULE_CART}/${ACTION_CHANGE}`, {
        item,
        errorCallback,
        successCallback: async () => {
            document.dispatchEvent(new CustomEvent(EVENT_MINICART_OPEN));
        }
    });

    const { callback } = detail;

    if (typeof callback === 'function') {
        await callback();
    }
});

document.addEventListener(EVENT_MINICART_PRODUCTS_REMOVE, async (event) => {
    const { detail } = event;
    if (!detail) {
        return;
    }

    const { keys } = detail;
    if (!keys) {
        return;
    }

    const errorCallback =
        typeof event.detail.errorCallback === 'function'
            ? await event.detail.errorCallback
            : () => {
            };

    await getStore().dispatch(`${SHOPIFY_MODULE_CART}/${ACTION_REMOVE_ITEMS}`, {
        keys,
        errorCallback,
        successCallback: async () => {
            document.dispatchEvent(new CustomEvent(EVENT_MINICART_OPEN));
        }
    });

    const { callback } = detail;

    if (typeof callback === 'function') {
        await callback();
    }
});

document.addEventListener(EVENT_MINICART_MEMBERSHIP_ADD, async (event) => {
    const detail = event.detail || {};

    const errorCallback = typeof event.detail.errorCallback === 'function' ? await event.detail.errorCallback : () => {};

    await getStore().dispatch(`${MODULE_MEMBER_PRODUCT}/${ACTION_ADD_LUX_KEY_ITEM}`, {
        errorCallback
    });

    const { callback } = detail;

    if (typeof callback !== 'function') {
        return;
    }

    await callback();
});

document.addEventListener(EVENT_MINICART_MEMBERSHIP_REMOVE, async (event) => {
    const detail = event.detail || {};

    const errorCallback =
        typeof event.detail.errorCallback === 'function'
            ? await event.detail.errorCallback
            : () => {
            };

    await getStore().dispatch(`${MODULE_MEMBER_PRODUCT}/${ACTION_REMOVE_LUX_KEY_ITEMS}`, {
        errorCallback
    });

    const { callback } = detail;

    if (typeof callback === 'function') {
        await callback();
    }
});

// add minicart opener for now
document.addEventListener(EVENT_MINICART_RELOAD, async () => {
    await getStore().dispatch(`${SHOPIFY_MODULE_CART}/${ACTION_GET_CART}`, {
        successCallback: async () => {
            document.dispatchEvent(new CustomEvent(EVENT_MINICART_OPEN));
        }
    });
});

document.addEventListener(EVENT_MINICART_RELOAD_SILENTLY, async () => {
    await getStore().dispatch(`${SHOPIFY_MODULE_CART}/${ACTION_GET_CART}`);
});

document.addEventListener(EVENT_MINICART_ERROR, async () => {
    await getStore().dispatch(`${SHOPIFY_MODULE_CART}/${ACTION_ERROR}`);
});

document.addEventListener(EVENT_MINICART_ITEMS_COUNT, updateCartCounters);

document.addEventListener('track:cart:removed', (event) => {
    if (![
        '/community/',
        '/apps/',
        '/a/',
        '/tools/'
    ]?.map((path) => {
        return !!window?.location?.pathname?.startsWith(path);
    })?.includes(true)) {
        return;
    }

    const { detail } = event;
    if (!detail) {
        return;
    }

    const { items } = detail;
    if (!items) {
        return;
    }

    if (!items?.length) {
        return;
    }

    items?.forEach((item) => {
        const properties = item?.properties;
        if (!properties) {
            return;
        }

        const keys = Object?.keys(properties);
        if (!keys) {
            return;
        }

        if (!keys?.length) {
            return;
        }

        if (!keys?.includes('_inveterate_subscription')) {
            return;
        }

        window?.location?.reload();
    });
});

document.addEventListener('track:cart:membership:added', () => {
    if (!window?.location?.href?.includes('/products/')) {
        return;
    }

    ;(() => {
        const memberPrices = Array.prototype.slice.apply(document.querySelectorAll(`[data-product-member-price]`));
        if (!memberPrices) {
            return;
        }

        if (!memberPrices?.length) {
            return;
        }

        memberPrices?.forEach((memberPrice) => {
            memberPrice?.classList?.remove('product-price__member--inactive');
            memberPrice?.classList?.remove('hidden');
        });
    })();

    ;(() => {
        const retailPrices = Array.prototype.slice.apply(document.querySelectorAll(`[data-sale-retail-price-container]`));
        if (!retailPrices) {
            return;
        }

        if (!retailPrices?.length) {
            return;
        }

        retailPrices.forEach((retailPrice) => {
            retailPrice?.classList?.add('product-price__regular--hidden');
            retailPrice?.classList?.add('hidden');
        });
    })();
});

document.addEventListener(EVENT_WISHLIST_ADD, async (event) => {
    const detail = event?.detail;
    if (!detail) {
        return;
    }

    const handle = detail?.handle;
    if (!handle) {
        return;
    }

    await getStore().dispatch(`${MODULE_NAME}/${ACTION_ADD_TO_WISHLIST}`,
        {
            handle
        }, {
            root: true
        }
    );
});

document.addEventListener(EVENT_WISHLIST_REMOVE, async (event) => {
    const detail = event?.detail;
    if (!detail) {
        return;
    }

    const handle = detail?.handle;
    if (!handle) {
        return;
    }

    await getStore().dispatch(`${MODULE_NAME}/${ACTION_REMOVE_FROM_WISHLIST}`, {
            handle
        }, {
            root: true
        }
    );
});

document.addEventListener(EVENT_WISHLIST_TOGGLE, async (event) => {
    const detail = event?.detail;
    if (!detail) {
        return;
    }

    const handle = detail?.handle;
    if (!handle) {
        return;
    }

    await getStore().dispatch(`${MODULE_NAME}/${ACTION_TOGGLE_ITEM_IN_WISHLIST}`, {
        handle
    }, {
        root: true
    });

    const callback = detail?.callback;
    if (!callback) {
        return;
    }

    if (typeof callback !== 'function') {
        return;
    }

    await callback();
});

document.addEventListener(EVENT_WISHLIST_INITIALIZED, (event) => {
    document.addEventListener('click', (event) => {
        let target = event?.target;

        if (!target?.dataset?.wishlistButton) {
            target = target.closest('[data-wishlist-button]');
        }

        if (!target) {
            return;
        }

        const handle = target?.dataset?.productHandle;
        if (!handle) {
            return;
        }

        target?.classList?.add('loading');

        document.dispatchEvent(new CustomEvent('wishlist:toggle', {
            detail: {
                handle,
                callback() {
                    target?.classList?.toggle('active');
                    target?.classList?.remove('loading');
                }
            }
        }));
    });

    const buttons = Array.from(document?.querySelectorAll('[data-wishlist-button]'));
    if (!buttons) {
        return;
    }

    if (!buttons?.length) {
        return;
    }

    const detail = event?.detail;
    if (!detail) {
        return;
    }

    const handles = detail?.handles;
    if (!handles) {
        return;
    }

    if (!handles?.length) {
        return;
    }

    buttons?.forEach((button) => {
        const handle = button?.dataset?.productHandle?.toLowerCase();
        if (!handle) {
            return;
        }

        if (!handles?.includes(handle)) {
            return;
        }

        button?.classList?.add('active');
    });
});

document.addEventListener('minicart:box:removed', () => {
    document.dispatchEvent(new CustomEvent("closeModal", {
        detail: "add-to-box-confirmation-modal"
    }));
});

export default {
    store,
    getStore,
};
